<!-- Footer -->
<footer class="cs-footer pt-sm-5 pt-4 bg-dark">
    <div class="container pt-3">
      <div class="row pb-sm-2">
        <div class="col-6 col-sm-3 col-lg-2 mb-4">
          <h3 class="h6 mb-2 pb-1 text-uppercase text-light">Help</h3>
          <ul class="nav nav-light flex-column">
            <li class="nav-item mb-2">
              <a href="#" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Delivery &amp; returns</a>
            </li>
            <li class="nav-item mb-2">
              <a href="contacts.html" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">FAQ</a>
            </li>
            <li class="nav-item mb-2">
              <a href="order-tracking.html" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Track order</a>
            </li>
            <li class="nav-item mb-2">
              <a href="blog.html" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Blog</a>
            </li>
            <li class="nav-item mb-2">
              <a href="contacts.html" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Contacts</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-sm-3 col-lg-2 col-xl-3 mb-4">
          <h3 class="h6 mb-2 pb-1 text-uppercase text-light pl-xl-6">Shop</h3>
          <ul class="nav nav-light flex-column pl-xl-6">
            <li class="nav-item mb-2">
              <a href="#" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">New arrivals</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Trending now</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Sales</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link mr-lg-0 mr-sm-4 p-0 font-weight-normal">Brands</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-lg-3 pb-2 pb-lg-0 mb-4">
          <h3 class="h6 mb-2 pb-1 text-uppercase text-light">Get in Touch</h3>
          <ul class="nav nav-light flex-column pb-3">
            <li class="nav-item text-nowrap mb-2">
              <span class="text-light mr-1">Call:</span>
              <a href="tel:4055550128" class="nav-link d-inline-block mr-lg-0 mr-sm-4 p-0 font-weight-normal">(405) 555-0128</a>
            </li>
            <li class="nav-item text-nowrap mb-2">
              <span class="text-light mr-1">Email:</span>
              <a href="mailto:support@example.coms" class="nav-link d-inline-block mr-lg-0 mr-sm-4 p-0 font-weight-normal">supportexample.com</a>
            </li>
          </ul>
          <a href="#" class="social-btn sb-solid sb-light mr-2">
            <i class="cxi-facebook"></i>
          </a>
          <a href="#" class="social-btn sb-solid sb-light mr-2">
            <i class="cxi-instagram"></i>
          </a>
          <a href="#" class="social-btn sb-solid sb-light mr-2">
            <i class="cxi-twitter"></i>
          </a>
          <a href="#" class="social-btn sb-solid sb-light mr-2">
            <i class="cxi-youtube"></i>
          </a>
          <a href="#" class="social-btn sb-solid sb-light">
            <i class="cxi-pinterest"></i>
          </a>
        </div>
        <div class="col-lg-4 col-xl-3 mb-4">
          <h3 class="h6 mb-3 pb-1 text-uppercase text-light">Download our App</h3>
          <div class="d-flex flex-wrap flex-sm-nowrap">
            <a href="#" class="btn-market btn-apple mb-3 mr-3" role="button">
              <span class="btn-market-subtitle">Download on the</span>
              <span class="btn-market-title">App Store</span>
            </a>
            <a href="#" class="btn-market btn-google mb-3" role="button">
              <span class="btn-market-subtitle">Download on the</span>
              <span class="btn-market-title">Google Play</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top border-light">
      <div class="container py-4">
        <div class="font-size-xs text-light">
          <span class="font-size-sm">&copy; </span>
          All rights reserved. Made with 
          <i class="cxi-heart mt-n1 mx-1 font-size-base text-primary align-middle"></i>
          <a href="https://createx.studio/" class="text-light" target="_blank" rel="noopener noreferrer">by Createx Studio</a>
        </div>
      </div>
    </div>
  </footer>