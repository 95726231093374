<app-nav></app-nav>

<main class="cs-page-wrapper">
    <section class="cs-carousel cs-controls-onhover">
        <div class="cs-carousel-inner" data-carousel-options=''>
    
          <!-- Slide 1 -->
          <div class="bg-size-cover py-xl-6" *ngIf="descuento_activo != undefined" style="background-image: url({{url}}obtener_banner_descuento/{{descuento_activo.banner}});">
            <div class="container pt-5 pb-6">
              <div class="row pb-lg-6">
                <div class="col-lg-6 offset-lg-1 offset-xl-0 pb-4 pb-md-6">
                  <h3 class="font-size-lg text-uppercase cs-from-left cs-delay-1">Desde {{descuento_activo.fecha_inicio}} hasta {{descuento_activo.fecha_fin}}</h3>
                  <h2 class="display-2 mb-lg-5 pb-3 cs-from-left">{{descuento_activo.titulo}}</h2>
                  <div class="mb-4 cs-scale-up cs-delay-2">
                    <a [routerLink]="['/productos']"  class="btn btn-outline-primary btn-lg">Ver productos</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- Slide 2 -->
          <div class="bg-size-cover py-xl-6" style="background-image: url(../../assets/img/ecommerce/home/hero-slider/02.jpg);">
            <div class="container pt-5 pb-6">
              <div class="row pb-lg-6">
                <div class="col-lg-6 offset-lg-1 offset-xl-0 pb-4 pb-md-6">
                  <h3 class="font-size-lg text-uppercase cs-from-top cs-delay-1">New Collection</h3>
                  <h2 class="display-2 mb-lg-5 pb-3 cs-scale-down">Fall-Winter 2021</h2>
                  <div class="mb-4 cs-scale-down cs-delay-2">
                    <a href="shop-catalog.html" class="btn btn-outline-primary btn-lg">Shop the collection</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- Slide 3 -->
          <div class="bg-size-cover py-xl-6" style="background-image: url(../../assets/img/ecommerce/home/hero-slider/03.jpg);">
            <div class="container pt-5 pb-6">
              <div class="row pb-lg-6">
                <div class="col-lg-6 offset-lg-1 offset-xl-0 pb-4 pb-md-6">
                  <h3 class="font-size-lg text-uppercase cs-from-left cs-delay-1">Limited Edition</h3>
                  <h2 class="display-2 mb-lg-5 pb-3 cs-from-left">Leather Issue</h2>
                  <div class="mb-4 cs-scale-up cs-delay-2">
                    <a href="shop-catalog.html" class="btn btn-outline-primary btn-lg">Shop the collection</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- Slide 4 -->
          <div class="bg-size-cover py-xl-6" style="background-image: url(../../assets/img/ecommerce/home/hero-slider/04.jpg);">
            <div class="container pt-5 pb-6">
              <div class="row pb-lg-6">
                <div class="col-lg-6 offset-lg-1 offset-xl-0 pb-4 pb-md-6">
                  <h3 class="font-size-lg text-uppercase cs-from-top cs-delay-1">Hottest Prices</h3>
                  <h2 class="display-2 mb-lg-5 pb-3 cs-scale-down">Kidswear Sales</h2>
                  <div class="mb-4 cs-scale-down cs-delay-2">
                    <a href="shop-catalog.html" class="btn btn-outline-primary btn-lg">Shop sale now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- Pager -->
        <div class="container position-relative">
          <div class="row mt-lg-n5">
            <div class="col-lg-7 col-md-8 col-sm-10 offset-lg-1 offset-xl-0">
              <div class="position-relative">
                <div id="pager" class="cs-pager cs-pager-inverse mb-xl-5 pb-5 pb-md-6">
                  <button type="button" data-nav="0">01</button>
                  <button type="button" data-nav="1">02</button>
                  <button type="button" data-nav="2">03</button>
                  <button type="button" data-nav="3">04</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section class="container pt-5 pt-lg-0">
        <div class="cs-carousel cs-nav-outside">
          <div class="cs-carousel-inner cs-carousel-inner-two" data-carousel-options=''>
            <!-- Category -->
            <a href="shop-catalog.html" class="d-block cs-image-scale cs-heading-highlight text-center">
              <div class="cs-image-inner mb-3 rounded">
                <img src="../../assets/img/ecommerce/home/categories/01.jpg" alt="Women’s">
              </div>
              <h3 class="h5 mb-1">Women’s</h3>
            </a>
            <!-- Category -->
            <a href="shop-catalog.html" class="d-block cs-image-scale cs-heading-highlight text-center">
              <div class="cs-image-inner mb-3 rounded">
                <img src="../../assets/img/ecommerce/home/categories/02.jpg" alt="Men’s">
              </div>
              <h3 class="h5 mb-1">Men’s</h3>
            </a>
            <!-- Category -->
            <a href="shop-catalog.html" class="d-block cs-image-scale cs-heading-highlight text-center">
              <div class="cs-image-inner mb-3 rounded">
                <img src="../../assets/img/ecommerce/home/categories/03.jpg" alt="Kids'">
              </div>
              <h3 class="h5 mb-1">Kids'</h3>
            </a>
          </div>
        </div>
    </section>
    

    <!-- New arrivals -->
    <section class="container-fluid px-xl-grid-gutter mt-5 mb-3 mb-sm-4 mt-md-0 mb-lg-5 py-5 py-lg-6">
        <div class="text-center mb-5 pb-2">
          <h2 class="h1">Nuevos productos</h2>
          <p class="font-size-lg text-muted mb-1">Echa un vistazo a nuestras últimas llegadas para la próxima temporada.</p>
          <a [routerLink]="['/productos']" class="font-size-lg">Ver la colección aquí</a>
        </div>

        <!-- Product carousel -->
        <div class="cs-carousel cs-nav-outside">
          <div class="cs-carousel-inner cs-carousel-inner-three" data-carousel-options='{
            
          }'>

            <!-- Item -->
            <div *ngFor="let item of new_productos">
              <div class="card card-product">
                <div class="card-product-img">
                  <a [routerLink]="['/productos/',item.slug]" class="card-img-top">
                    <img [src]="url +'obtener_portada/'+item.portada" alt="Product image">
                  </a>
                  <div class="card-product-widgets-top">
                    <div class="star-rating">
                      <i class="sr-star cxi-star-filled active"></i>
                      <i class="sr-star cxi-star-filled active"></i>
                      <i class="sr-star cxi-star-filled active"></i>
                      <i class="sr-star cxi-star-filled active"></i>
                      <i class="sr-star cxi-star-filled active"></i>
                    </div>
                  </div>
                  <div class="card-product-widgets-bottom">
                    <a href="#" class="btn-wishlist ml-auto" data-toggle="tooltip" data-placement="left" title="Add to wishlist"></a>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-product-title text-truncate mb-2">
                    <a [routerLink]="['/productos/',item.slug]" class="nav-link">{{item.titulo}}</a>
                  </h3>
                  <div class="d-flex align-items-center" *ngIf="descuento_activo == undefined">
                    <span class="h5 d-inline-block mb-0">${{item.precio}}</span>
                  </div>
                  <div class="d-flex align-items-center" *ngIf="descuento_activo != undefined">
                    <span class="h5 d-inline-block text-danger mb-0">${{item.precio | descuento: descuento_activo.descuento}}</span>
                    <del class="d-inline-block ml-2 pl-1 text-muted">${{item.precio}}</del>
                  </div>
                </div>
              </div>
            </div>

         
          </div>
        </div>
    </section>

     <!-- Trending products (carousel) -->
    <section class="py-5" style="background-color: #e5e8ed;">
        <div class="container my-lg-2 py-2 py-md-4">
          <div class="d-flex align-items-center justify-content-between pb-sm-1 mb-5">
            <h2 class="h1 mb-0">Productos mas vendidos</h2>
            <div class="tns-custom-controls cs-controls-inverse" id="custom-controls-trending" tabindex="0">
              <button type="button" data-controls="prev" tabindex="-1">
                <i class="cxi-arrow-left"></i>
              </button>
              <button type="button" data-controls="next" tabindex="-1">
                <i class="cxi-arrow-right"></i>
              </button>
            </div>
          </div>
          <div class="cs-carousel pb-2">
            <div class="cs-carousel-inner cs-carousel-inner-four" data-carousel-options='{
              
            }'>

              <!-- Item -->
              <div *ngFor="let item of mas_vendidos">
                <div class="card card-product mx-auto">
                  <div class="card-product-img">
                    <a href="shop-single.html" class="card-img-top">
                      <img [src]="url +'obtener_portada/'+item.portada" alt="Product image">
                    </a>
                    <div class="card-product-widgets-top">
                      <div class="star-rating ml-auto">
                        <i class="sr-star cxi-star-filled active"></i>
                        <i class="sr-star cxi-star-filled active"></i>
                        <i class="sr-star cxi-star-filled active"></i>
                        <i class="sr-star cxi-star-filled active"></i>
                        <i class="sr-star cxi-star-filled active"></i>
                      </div>
                    </div>
                    <div class="card-product-widgets-bottom">
                      <a href="#" class="btn-wishlist ml-auto" data-toggle="tooltip" data-placement="left" title="Add to wishlist"></a>
                    </div>
                  </div>
                  <div class="card-body bg-0 pb-2">
                    <h3 class="card-product-title text-truncate mb-2">
                      <a href="shop-single.html" class="nav-link">{{item.titulo}}</a>
                    </h3>
                    <div class="d-flex align-items-center" *ngIf="descuento_activo == undefined">
                        <span class="h5 d-inline-block mb-0">${{item.precio}}</span>
                      </div>
                      <div class="d-flex align-items-center" *ngIf="descuento_activo != undefined">
                        <span class="h5 d-inline-block text-danger mb-0">${{item.precio | descuento: descuento_activo.descuento}}</span>
                        <del class="d-inline-block ml-2 pl-1 text-muted">${{item.precio}}</del>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="text-center pt-4 pt-md-5">
            <a [routerLink]="['/productos']" routerLinkActive="router-link-active"  class="btn btn-primary btn-lg">Ver productos</a>
          </div>
        </div>
    </section>

    <!-- Popular categories -->
    <section class="container mt-1 mb-3 my-sm-4 py-5 py-lg-6">
        <h2 class="h1 mb-5 pb-3 text-center">Categorias</h2>
        <div class="cs-carousel cs-nav-outside">
          <div class="cs-carousel-inner cs-carousel-inner-five" data-carousel-options='{
            
          }'>

            <!-- Category -->
            <div class="pb-2" *ngFor="let item of categorias">
              <a [routerLink]="['/productos/categoria/',item.titulo.toLowerCase()]" class="d-block cs-image-scale cs-heading-highlight text-center">
                <div class="cs-image-inner rounded-circle mx-auto mb-4" style="max-width: 180px;">
                  <img [src]="item.portada" alt="Category image">
                </div>
                <h3 class="h5 mb-3">{{item.titulo}}</h3>
              </a>
            </div>

           
          </div>
        </div>
    </section>

    <!-- Mobile app CTA -->
    <section class="bg-dark">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5">
              <img src="../../assets/img/ecommerce/home/mobile-app-illustration.png" class="d-block mx-auto my-4 mb-lg-0 mt-lg-n4" width="498" alt="Illustartion">
            </div>
            <div class="col-md-7 col-lg-6 pt-3 pb-5 pt-md-5 pb-md-4 ml-auto">
              <h2 class="text-light text-center text-md-left pb-3">¡Disfrute de las compras móviles con nuestra aplicación Createx Store!</h2>
              <div class="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-md-start">
                <a href="#" class="btn-market btn-apple mb-3 mx-2 ml-md-0 mr-md-3" role="button">
                  <span class="btn-market-subtitle">Descarga en el</span>
                  <span class="btn-market-title">App Store</span>
                </a>
                <a href="#" class="btn-market btn-google mb-3 mx-2 mx-md-0" role="button">
                  <span class="btn-market-subtitle">Descarga en el</span>
                  <span class="btn-market-title">Google Play</span>
                </a>
              </div>
            </div>
          </div>
        </div>
    </section>

    <!-- Services -->
    <section class="container pt-5 pb-2 pb-sm-5 py-md-5 py-lg-6">
        <div class="row py-3">
          <div class="col-sm-6 col-md-3 text-center mb-md-0 mb-4 pb-md-0 pb-3">
            <img class="mb-4" src="../../assets/img/ecommerce/home/services/delivery.svg" width="48" alt="Worldwide Shipping">
            <h5 class="h5 mb-2">Envío rápido a todo el mundo</h5>
            <p class="mb-0 text-muted">Get free shipping over $250</p>
          </div>
          <span class="divider-vertical d-sm-block d-none"></span>
          <div class="col-sm-6 col-md-3 text-center mb-md-0 mb-4 pb-md-0 pb-3">
            <img class="mb-4" src="../../assets/img/ecommerce/home/services/support.svg" width="48" alt="Customer Support">
            <h5 class="h5 mb-2">Atención al cliente 24/7 días a la semana</h5>
            <p class="mb-0 text-muted">Friendly 24/7 customer support</p>
          </div>
          <span class="divider-vertical d-sm-block d-none"></span>
          <div class="col-sm-6 col-md-3 text-center mb-md-0 mb-4 pb-md-0 pb-3">
            <img class="mb-4" src="../../assets/img/ecommerce/home/services/guarantee.svg" width="48" alt="Customer Support">
            <h5 class="h5 mb-2">Garantía de devolución del dinero</h5>
            <p class="mb-0 text-muted">We return money within 30 days</p>
          </div>
          <span class="divider-vertical d-sm-block d-none"></span>
          <div class="col-sm-6 col-md-3 text-center mb-md-0 mb-4 pb-md-0 pb-3">
            <img class="mb-4" src="../../assets/img/ecommerce/home/services/payment.svg" width="48" alt="Customer Support">
            <h5 class="h5 mb-2">Pago seguro en línea</h5>
            <p class="mb-0 text-muted">Accept all major credit cards</p>
          </div>
        </div>
    </section>


    <!-- Insagram -->
    <section class="pt-5 pb-4 pt-lg-6 pb-lg-5 border-top border-bottom">
        <div class="container pt-md-4 pb-2 pt-lg-0 pb-lg-0">
          <div class="row">
            <div class="col-md-4 text-center text-md-left pb-2 pb-md-0 mb-4 mb-md-0">
              <p class="text-dark text-uppercase mb-2">Siguenos en Instagram</p>
              <h2 class="h1 pb-2 pb-md-3">createx_store</h2>
              <a href="#" class="btn btn-outline-primary btn-lg">
                <i class="cxi-instagram font-size-lg mr-1"></i>
                Siguenos en Instagram
              </a>
            </div>
            <div class="col-md-8">
              <div class="cs-carousel cs-nav-outside">
                <div class="cs-carousel-inner cs-carousel-inner-six" data-carousel-options='{
                  
                }'>
                  <!-- Image -->
                  <div>
                    <img src="../../assets/img/ecommerce/home/instagram/01.jpg" alt="Image">
                  </div>
                  <!-- Image -->
                  <div>
                    <img src="../../assets/img/ecommerce/home/instagram/02.jpg" alt="Image">
                  </div>
                  <!-- Image -->
                  <div>
                    <img src="../../assets/img/ecommerce/home/instagram/03.jpg" alt="Image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</main>


<app-footer></app-footer>
