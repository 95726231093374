<app-nav></app-nav>


<main  class="cs-page-wrapper">
    <nav class="bg-secondary mb-3" aria-label="breadcrumb">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt mb-0">
            <li class="breadcrumb-item">
              <a href="index.html"><i class="cxi-home"></i></a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Checkout</li>
          </ol>
        </div>
      </nav>

    <section class="container pt-3 pt-md-4 pb-3 pb-sm-4 pb-lg-5 mb-4">
        <div class="row">
            <div class="col-lg-6">
                <div class="px-md-5">
                    <h3 class="modal-title mt-4 mb-0 text-center">Iniciar Sesión</h3>
                    <p class="font-size-sm text-muted text-center">Sign in to your account using email and password provided during registration.</p>
                    <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                      <div class="form-group">
                        <label for="signin-email">Correo electrónico</label>
                        <input type="email" class="form-control" placeholder="Correo electrónico" required="" name="email" [(ngModel)]="user.email">
                      </div>
                      <div class="form-group">
                        <label for="signin-password" class="form-label">Contraseña</label>
                        <div class="cs-password-toggle input-group-overlay">
                          <input type="password" class="form-control appended-form-control" id="signin-password" placeholder="Tu contraseña" required="" name="password" [(ngModel)]="user.password">
                          <div class="input-group-append-overlay">
                            <label class="btn cs-password-toggle-btn input-group-text">
                              <input type="checkbox" class="custom-control-input">
                              <i class="cxi-eye cs-password-toggle-indicator"></i>
                              <span class="sr-only">Show password</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center form-group">
                        <a href="#" class="font-size-sm text-decoration-none">Olvido su contraseña?</a>
                      </div>
                      <button type="submit" class="btn btn-primary btn-block">Iniciar sesión</button>
                      <p class="font-size-sm pt-4 mb-0">
                        No tienes una cuenta?
                        <a href="#" class="font-weight-bold text-decoration-none">Registrate</a>
                      </p>
                    </form>
                  </div>
            </div>
            <div class="col-lg-6">
                <div class="px-md-5">
                    <h3 class="modal-title mt-4 mb-0 text-center">Registro rápido</h3>
                    <p class="font-size-sm text-muted text-center">Sign in to your account using email and password provided during registration.</p>
                    <form >
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="signin-email">Nombres completos</label>
                                    <input type="email" class="form-control"  placeholder="Names" required="">
                                </div>
                                <div class="col-lg-6">
                                    <label for="signin-email">Apellidos completos</label>
                                    <input type="email" class="form-control"  placeholder="Fullname" required="">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="signin-email">Email</label>
                            <input type="email" class="form-control" placeholder="Your email address" required="">
                        </div>
                        <div class="form-group">
                            <label for="signin-password" class="form-label">Password</label>
                            <div class="cs-password-toggle input-group-overlay">
                            <input type="password" class="form-control appended-form-control" id="signin-password" placeholder="Your password" required="">
                            <div class="input-group-append-overlay">
                                <label class="btn cs-password-toggle-btn input-group-text">
                                <input type="checkbox" class="custom-control-input">
                                <i class="cxi-eye cs-password-toggle-indicator"></i>
                                <span class="sr-only">Show password</span>
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center form-group">
                            <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="remember-me" checked="">
                            <label for="remember-me" class="custom-control-label">Remember me</label>
                            </div>
                            <a href="#" class="font-size-sm text-decoration-none">Forgot password?</a>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Sign in</button>
                        <p class="font-size-sm pt-4 mb-0">
                            Ya tienes una cuenta?
                            <a href="#" class="font-weight-bold text-decoration-none">Registrarme</a>
                        </p>
                    </form>
                  </div>
            </div>
        </div>
    </section>

</main>


<app-footer></app-footer>