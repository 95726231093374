<header class="cs-header">
  
    <!-- Navbar -->
    <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
    <div class="navbar navbar-expand-lg navbar-light bg-light navbar-sticky" data-fixed-element>
      <div class="container px-0 px-xl-3">
        <a href="index.html" class="navbar-brand order-lg-1 mr-0 pr-lg-3 mr-lg-4">
          <img src="assets/img/ecommerce/logo.svg" alt="Createx Logo" width="130">
        </a>
        <!-- Search desktop -->
        <div class="input-group-overlay ml-4 d-lg-block d-none order-lg-3" style="max-width: 21rem;">
          <input class="form-control appended-form-control" type="text" placeholder="Search for products...">
          <div class="input-group-append-overlay">
            <span class="input-group-text"><i class="cxi-search lead align-middle"></i></span>
          </div>
        </div>
        <!-- Toolbar -->
        <div class="d-flex align-items-center order-lg-3">
          <ul class="nav nav-tools flex-nowrap">
            
              <ng-container *ngIf="user_lc == undefined">
                <li class="nav-item d-lg-block d-none mb-0">
                  <a [routerLink]="['/login']" class="nav-tool">
                    <i class="cxi-profile nav-tool-icon"></i>
                    <span class="nav-tool-label">Iniciar sesión</span>
                  </a>
                </li>
                
              </ng-container>
              <ng-container *ngIf="user_lc">
                <li class="nav-item d-lg-block d-none dropdown">
                  <a class="nav-tool dropdown-toggle" toggle="dropdown" style="cursor: pointer;">
                    <i class="cxi-profile nav-tool-icon"></i>
                    <span class="nav-tool-label">{{user_lc.nombres}}</span>
                  </a>
                  <div class="dropdown-menu">

                    <a [routerLink]="['/cuenta/perfil']" routerLinkActive="active"  class="dropdown-item">
                      <i class="cxi-profile mr-2"></i>
                      Perfil
                      <!-- <span class="badge badge-pill badge-danger ml-2">3</span> -->
                    </a>
                    <a href="#" class="dropdown-item">
                      <i class="cxi-comment mr-2"></i>
                      Messages
                      <span class="badge badge-pill badge-success ml-2">5</span>
                    </a>
                    <a (click)="logout()" class="dropdown-item" style="cursor: pointer;">
                      <i class="cxi-logout mr-2"></i>
                      Cerrar sesión

                    </a>
                  </div>
                </li>
              </ng-container>
        
            <li class="divider-vertical mb-0 d-lg-block d-none"></li>
           
            <li class="nav-item align-self-center mb-0" *ngIf="user_lc != undefined">
              <a style="cursor: pointer;" class="nav-tool pr-lg-0" (click)="op_modalcart()">
                <i class="cxi-cart nav-tool-icon"></i>
                <span class="badge badge-success align-middle mt-n1 ml-2 px-2 py-1 font-size-xs">{{carrito_arr.length}}</span>
              </a>
            </li>
            <li class="nav-item align-self-center mb-0" *ngIf="user_lc == undefined">
              <a style="cursor: pointer;" class="nav-tool pr-lg-0" [routerLink]="['/login']" >
                <i class="cxi-cart nav-tool-icon"></i>
                <span class="badge badge-success align-middle mt-n1 ml-2 px-2 py-1 font-size-xs">0</span>
              </a>
            </li>
            <li class="divider-vertical mb-0 d-lg-none d-block"></li>
            <li class="nav-item mb-0">
              <button class="navbar-toggler mt-n1 mr-n3" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
              </button>
            </li>
          </ul>
        </div>
        <!-- Navbar collapse -->
        <nav class="collapse navbar-collapse order-lg-2" id="navbarCollapse">
          <!-- Search mobile -->
          <div class="input-group-overlay form-group mb-0 d-lg-none d-block">
            <input type="text" class="form-control prepended-form-control rounded-0 border-0" placeholder="Search for products...">
            <div class="input-group-prepend-overlay">
              <span class="input-group-text">
                <i class="cxi-search font-size-lg align-middle mt-n1"></i>
              </span>
            </div>
          </div>
          <!-- Menu -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Inicio</a>
            </li>
            <li class="nav-item dropdown">
              <a [routerLink]="['/productos']" routerLinkActive="active"  class="nav-link dropdown-toggle" data-toggle="dropdown">Tienda</a>
              <ul class="dropdown-menu">

                <li *ngFor="let item of config_global.categorias">
                  <a [routerLink]="['/productos/categoria/',item.titulo.toLowerCase()]" routerLinkActive="router-link-active"  class="dropdown-item"><i class="{{item.icono}}"></i> &nbsp; {{item.titulo}}</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/contacto']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Contacto</a>
            </li>
           
          </ul>
        </nav>
      </div>
    </div>
  </header>

  <div id="cart" class="cs-offcanvas cs-offcanvas-right">

    <!-- Header -->
    <div class="cs-offcanvas-cap align-items-center border-bottom">
      <h2 class="h5 mb-0">Your cart (4)</h2>
      <button class="close mr-n1" type="button" data-dismiss="offcanvas" aria-label="Close" (click)="op_modalcart()">
        <span class="h3 font-weight-normal mb-0" aria-hidden="true" >×</span>
      </button>
    </div>

    <!-- Body -->
    <div class="cs-offcanvas-body">
      <!-- Item -->
      <div class="media p-4 border-bottom mx-n4" *ngFor="let item of carrito_arr">
        <a  [routerLink]="['/productos/',item.producto.slug]" target="_blank" style="min-width: 80px;">
          <img [src]="url +'obtener_portada/'+item.producto.portada" width="80" alt="Product thumb">
        </a>
        <div class="media-body pl-3">
          <div class="d-flex justify-content-between">
            <div class="pr-2">
              <h3 class="font-size-sm mb-3">
                <a  [routerLink]="['/productos/',item.producto.slug]" target="_blank" class="nav-link font-weight-bold">{{item.producto.titulo}}</a>
              </h3>
              <ul class="list-unstyled font-size-xs mt-n2 mb-2">
                <li class="mb-0"><span class="text-muted">{{item.producto.titulo_variedad}}:</span> {{item.variedad}}</li>
              </ul>
              <div class="d-flex align-items-center">
                <input type="number" class="form-control form-control-sm bg-light mr-3" readonly style="width: 4.5rem;" value="{{item.cantidad}}">
                <div class="text-nowrap">
                  <!-- <strong class="text-danger"></strong>  -->
                  <span style="font-size: 1.2rem !important;" class="font-size-xs text-muted" *ngIf="descuento_activo == undefined">${{item.producto.precio}}</span>
                  <span style="font-size: 1.2rem !important;" class="font-size-xs text-muted" *ngIf="descuento_activo != undefined">${{item.producto.precio | descuento:descuento_activo.descuento}}</span>
                </div>
              </div>
              <button class="btn btn-link btn-sm text-decoration-none px-0 pb-0">
                Mover a
                <i class="cxi-heart ml-1"></i>
              </button>
            </div>
            <div class="nav-muted mr-n2">
              <a class="nav-link px-2 mt-n2" title="Quitar"  style="cursor: pointer;" (click)="eliminar_item(item._id)">
                <i class="cxi-delete"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <!-- Footer -->
    <div class="cs-offcanvas-cap flex-column border-top">
      <div class="d-flex align-items-center justify-content-between mb-3 pb-1">
        <span class="text-muted mr-2">Subtotal:</span>
        <span class="h5 mb-0">${{subtotal}}</span>
      </div>
      <a [routerLink]="['/carrito']" class="btn btn-primary btn-lg btn-block">
        <i class="cxi-credit-card font-size-lg mt-n1 mr-1"></i>
        Checkout
      </a>
    </div>
  </div>
  
  