

  <div class="sidebar-sticky" data-sidebar-sticky-options='{
    "topSpacing": 120,
    "minWidth": 991
  }'>
    <div class="sidebar-sticky-inner">
      <div class="card">
        <div class="card-body" *ngIf="user_lc">
          <h5 class="card-title mb-1">{{user_lc.nombres}}</h5>
          <p class="card-text text-muted mb-lg-0">{{user_lc.email}}</p>
          <a href="#account-menu" class="btn btn-primary btn-block d-lg-none" data-toggle="collapse">Account Menu</a>
        </div>
        <div id="account-menu" class="collapse d-lg-block">
          <div class="list-group list-group-flush border-top">
            <a [routerLink]="['/cuenta/perfil']" routerLinkActive="active"  class="list-group-item list-group-item-action d-flex align-items-center">
              <i class="cxi-profile font-size-lg mr-2"></i>
              <span>Mi perfil</span>
            </a>
            <a [routerLink]="['/cuenta/direcciones']" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
              <i class="cxi-bag font-size-lg mr-2"></i>
              <span>Mis direcciónes</span>
            </a>
            <a [routerLink]="['/cuenta/ordenes']" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
              <i class="cxi-heart font-size-lg mr-2"></i>
              <span>Mis ordenes</span>
            </a>
            <a [routerLink]="['/cuenta/reviews']" routerLinkActive="active" class="list-group-item list-group-item-action d-flex align-items-center">
              <i class="cxi-star font-size-lg mr-2"></i>
              <span>Mis reseñas</span>
            </a>
            <a style="cursor: pointer;" class="list-group-item list-group-item-action d-flex align-items-center" (click)="logout()">
              <i class="cxi-logout font-size-lg mr-2"></i>
              <span>Cerrar sesión</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>